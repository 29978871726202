<template>
  <div>
    <v-container class="my-16">
      <v-row justify="center">
        <v-col md="10">
          <v-card color="#55a220" rounded="lg">
            <v-card-title>
              <v-avatar color="success" class="mr-3">
                <v-icon large class="textColor">
                  {{ icons.mdiCheckBold }}
                </v-icon>
              </v-avatar>
              <h3 class="text-h6 ma-0 pa-0" style="line-height: 1">
                <div class="textColor">{{ $t('siparisBilgi') }}</div>
              </h3>
              <div>
                <small class="text-caption textColor">
                  {{ $t('siparisBasarili') }}
                </small>
              </div>
            </v-card-title>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon class="textColor">
                          {{ icons.mdiQrcodeScan }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle class="textColor"> B2B {{ $t('siparisNumarasi') }}</v-list-item-subtitle>
                        <v-list-item-title class="textColor">
                          {{ detail.head.id }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3">
                  <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon class="textColor">
                          {{ icons.mdiCalendar }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle class="textColor">{{ $t('teslimTarihi') }}</v-list-item-subtitle>
                        <v-list-item-title class="textColor">
                          {{ detail.head.siparis_tarihi }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3">
                  <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon class="textColor">
                          {{ icons.mdiContactlessPayment }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle class="textColor">{{ $t('odeme') }}</v-list-item-subtitle>
                        <v-list-item-title v-if="detail.head.odeme_tipi === '1'" class="mb-2">
                          {{ $t('krediKarti') }}
                        </v-list-item-title>
                        <v-list-item-title v-if="detail.head.odeme_tipi === '2'" class="mb-2 textColor">
                          {{ $t('bankaHavalesi') }}
                        </v-list-item-title>
                        <v-list-item-title v-if="detail.head.odeme_tipi === '2'"> </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3">
                  <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon class="textColor">
                          {{ icons.mdiTruckFast }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle class="textColor">{{ $t('teslimTarihi') }}</v-list-item-subtitle>
                        <v-list-item-title class="mb-2 textColor">
                          {{ detail.head.teslim_tarihi.slice(0, 10) }}
                        </v-list-item-title>
                        <v-list-item-title>
                          <v-chip label x-small color="white">
                            <span class="black--text">
                              {{ detail.head.kargo_bilgisi.kargo_adi }}
                            </span>
                          </v-chip>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="10">
          <v-alert type="error">
            <span class="">
              {{ $t('note') }}
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col md="10">
          <v-card color="grey lighten-1" rounded="lg">
            <v-card-title>
              <v-avatar class="mr-3">
                <v-icon :size="24">
                  {{ icons.mdiFormatListText }}
                </v-icon>
              </v-avatar>

              <div>
                <h3 class="text-h6 ma-0 pa-0" style="line-height: 1">{{ $t('siparisListesi') }}</h3>
                <small class="text-caption"> {{ $t('siparisEdlienUrunListesi') }} </small>
              </div>
            </v-card-title>
            <v-card-text>
              <v-list color="transparent" dense class="pa-0">
                <div v-for="(line, index) in detail.lines" :key="`LIST-${index}`">
                  <v-hover v-slot="{ hover }">
                    <v-list-item
                      :three-line="!$vuetify.breakpoint.mdAndUp"
                      :two-line="$vuetify.breakpoint.mdAndUp"
                      :class="hover ? 'null' : null"
                      class="py-2 py-md-0 px-0 px-md-2"
                    >
                      <v-list-item-avatar class="mx-0 mx-md-3" :size="$vuetify.breakpoint.mdAndUp ? 75 : 50">
                        <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 75 : 50" tile>
                          <v-img
                            :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                            :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                            :src="
                              line.resim
                                ? 'https://portalapi.hatko.com/' + line.resim
                                : require('@/assets/images/misc/not-image.png')
                            "
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-action>
                        <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 120 : 80" color="transparent">
                          <CounterTo
                            :count="false"
                            :uid="line.uid"
                            :plant-id="line.selected_plant"
                            :miktar="line.qty"
                            :birim="line.birim"
                            :birim-ritim="line.birim_ritmi"
                            bg-color="counter"
                            button-color="black"
                            :light="true"
                            @refreshBasket="refreshBasket()"
                          />
                        </v-sheet>
                      </v-list-item-action>
                      <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-2'">
                        <v-list-item-title
                          class="black--text text-caption text-md-body-2 font-weight-thin pt-1"
                          :style="$vuetify.breakpoint.mdAndUp ? null : 'white-space: normal !important'"
                        >
                          {{ line.baslik }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="mt-1">
                          {{ line.total_price | currency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-hover>
                  <v-divider></v-divider>
                </div>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> {{ $t('nakliyeUcreti') }} </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ detail.head.kargo_ucreti | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> {{ $t('toplamTutari') }}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ detail.head.total_price | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right"> {{ $t('kdv') }} </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ detail.head.tax_price | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right">
                      <p class="pa-0 ma-0">{{ $t('siparisToplamTutari') }}</p>
                      <small class="text--disabled">{{ $t('kdvDahil') }}</small>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ detail.head.order_price | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="5">
          <v-alert outlined color="warning" class="rounded-lg" height="100%">
            <v-card color="transparent" flat rounded="lg" height="100%">
              <v-card-title>
                <v-icon color="warning" class="mr-3">
                  {{ icons.mdiMapMarker }}
                </v-icon>

                <div class="text-h6 warning--text">{{ $t('teslimatAdresi') }}</div>
                <v-spacer></v-spacer>
                <v-chip small color="warning" class="font-weight-light">
                  {{ detail.head.teslimat_adresi.address_title }}
                </v-chip>
              </v-card-title>

              <v-card-text>
                <v-list class="pa-0" color="transparent">
                  <v-list-item class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="warning--text"> {{ $t('aliciAdi') }} </v-list-item-subtitle>
                      <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                        {{ detail.head.teslimat_adresi.name }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3"></v-divider>
                  <v-list-item three-line class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="warning--text"> {{ $t('adres') }} </v-list-item-subtitle>
                      <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                        {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                        {{ detail.head.teslimat_adresi.city_txt }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3"></v-divider>

                  <v-list-item class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="warning--text"> {{ $t('aliciTelefonu') }} </v-list-item-subtitle>
                      <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                        {{ detail.head.teslimat_adresi.mobile }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-alert>
        </v-col>
        <v-col cols="12" md="5">
          <v-alert outlined color="secondary" height="100%">
            <v-card color="transparent" flat rounded="lg" height="100%">
              <v-card-title>
                <v-icon color="secondary" class="mr-3">
                  {{ icons.mdiReceipt }}
                </v-icon>

                <div class="text-h6 ma-0 pa-0 secondary--text">{{ $t('faturaBilgileri') }}</div>

                <v-spacer></v-spacer>
                <v-chip small color="secondary" class="font-weight-light"> {{ $t('kurumsal') }} </v-chip>
              </v-card-title>

              <v-card-text>
                <v-list class="pa-0" color="transparent">
                  <v-list-item class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="secondary--text">{{ $t('faturaAdi') }} </v-list-item-subtitle>
                      <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                        {{ detail.head.fatura_adresi.name }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3"></v-divider>
                  <v-list-item three-line class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="secondary--text"> {{ $t('adres') }} </v-list-item-subtitle>
                      <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                        {{ detail.head.fatura_adresi.address }} {{ detail.head.fatura_adresi.state }} /
                        {{ detail.head.fatura_adresi.city_txt }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3"></v-divider>
                  <v-list-item class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="secondary--text">
                        {{ $t('vergiDairesiNumarasi') }}
                      </v-list-item-subtitle>
                      <div style="font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                        {{ detail.head.fatura_adresi.state }} / {{ detail.head.fatura_adresi.mobile }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-alert>
        </v-col>
      </v-row>

      <!-- 
      <v-row v-if="detail.head.buyer_note !== 'null'" justify="center">
        <v-col cols="12" md="10">
          <v-alert text outlined color="error"> Not : {{ detail.head.buyer_note }} </v-alert>
        </v-col>
      </v-row>
       -->
    </v-container>
  </div>
</template>

<script>
import CounterTo from '@/components/CounterTo.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import {
  mdiCalendar,
  mdiCheckBold,
  mdiContactlessPayment,
  mdiFormatListText,
  mdiMapMarker,
  mdiQrcodeScan,
  mdiReceipt,
  mdiTruckFast,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  components: {
    CounterTo,
  },

  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const { isDark } = useAppConfig()

    const { router } = useRouter()
    const status = ref(false)
    const detail = ref({})
    //  const yukleniyor = ref(true)

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    onMounted(() => {
      console.log(store.state.detail1)
      detail.value = store.state.detail1
      //yukleniyor.value = true
    })

    return {
      status,
      isDark,
      //yukleniyor,
      detail,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoDark: themeConfig.app.logoDark,
      textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.textLogoDark,
      icons: {
        mdiCalendar,
        mdiFormatListText,
        mdiReceipt,
        mdiMapMarker,
        mdiCheckBold,
        mdiQrcodeScan,
        mdiTruckFast,
        mdiContactlessPayment,
      },

      // Icons
    }
  },
  mounted() {
    this.$swal.close()
  },
}
</script>

<style>
.textColor {
  color: white !important;
}
</style>
